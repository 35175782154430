export const COLORS = {
  WHITE: 'white',
  BLACK: 'black',
  LIGHT: 'light',
  DARK: 'dark',
  INFO: 'info',
  LINK: 'link',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  WHITE_TER: 'white-ter'
};

export const ICON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const IMAGE_DIMENSION = {
  IS_16X16: 'is-16x16',
  IS_24X24: 'is-24x24',
  IS_32X32: 'is-32x32',
  IS_48X48: 'is-48x48',
  IS_64X64: 'is-64x64',
  IS_96X96: 'is-96x96',
  IS_128X128: 'is-128x128',
}

export const IMAGE_RATIO = {
  IS_SQUARE: 'is-square',
  IS_1by1: 'is-1by1',
  IS_5by4: 'is-5by4',
  IS_4by3: 'is-4by3',
  IS_3by2: 'is-3by2',
  IS_5by3: 'is-5by3',
  IS_16by9: 'is-16by9',
  IS_2by1: 'is-2by1',
  IS_3by1: 'is-3by1',
  IS_4by5: 'is-4by5',
  IS_3by4: 'is-3by4',
  IS_2by3: 'is-2by3',
  IS_3by5: 'is-3by5',
  IS_9by16: 'is-9by16',
  IS_1by2: 'is-1by2',
  IS_1by3: 'is-1by3', 
}